// Generated by Framer (20caf11)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Video } from "https://framerusercontent.com/modules/lRDHiNWNVWmE0lqtoVHP/zEmAposrjympIK2ybAcQ/Video.js";
import * as localizedValues from "./LuhsnYHvd-0.js";
import * as localizedValues1 from "./LuhsnYHvd-1.js";
const VideoFonts = getFonts(Video);

const cycleOrder = ["Rgf0c6E73", "LqrusrREa", "sorjaZuFM"];

const serializationHash = "framer-th6W2"

const variantClassNames = {LqrusrREa: "framer-v-54hjpt", Rgf0c6E73: "framer-v-lsd17y", sorjaZuFM: "framer-v-yy78ni"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {qFQHIOpJU: localizedValues1, VMMHDo1EA: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {desktop: "Rgf0c6E73", mobile: "sorjaZuFM", tablet: "LqrusrREa"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "Rgf0c6E73"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Rgf0c6E73", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-lsd17y", className, classNames)} data-framer-name={"desktop"} layoutDependency={layoutDependency} layoutId={"Rgf0c6E73"} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", ...style}} {...addPropertyOverrides({LqrusrREa: {"data-framer-name": "tablet"}, sorjaZuFM: {"data-framer-name": "mobile"}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-xj2lex-container"} layoutDependency={layoutDependency} layoutId={"Fmcn0JDzc-container"}><Video backgroundColor={"rgba(0, 0, 0, 0)"} borderRadius={0} bottomLeftRadius={0} bottomRightRadius={0} controls={false} height={"100%"} id={"Fmcn0JDzc"} isMixedBorderRadius={false} layoutId={"Fmcn0JDzc"} loop muted objectFit={"cover"} playing posterEnabled={false} srcType={"URL"} srcUrl={"https://files.cec.com/share/didacta-bari-24-slide-1400x650-ita.mp4"} startTime={0} style={{height: "100%", width: "100%"}} topLeftRadius={0} topRightRadius={0} volume={25} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-th6W2.framer-1bk6qmt, .framer-th6W2 .framer-1bk6qmt { display: block; }", ".framer-th6W2.framer-lsd17y { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 807px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 1400px; }", ".framer-th6W2 .framer-xj2lex-container { flex: 1 0 0px; height: 100%; position: relative; width: 1px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-th6W2.framer-lsd17y { gap: 0px; } .framer-th6W2.framer-lsd17y > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-th6W2.framer-lsd17y > :first-child { margin-left: 0px; } .framer-th6W2.framer-lsd17y > :last-child { margin-right: 0px; } }", ".framer-th6W2.framer-v-54hjpt.framer-lsd17y { width: 800px; }", ".framer-th6W2.framer-v-yy78ni.framer-lsd17y { width: 390px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 807
 * @framerIntrinsicWidth 1400
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"LqrusrREa":{"layout":["fixed","fixed"]},"sorjaZuFM":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerLuhsnYHvd: React.ComponentType<Props> = withCSS(Component, css, "framer-th6W2") as typeof Component;
export default FramerLuhsnYHvd;

FramerLuhsnYHvd.displayName = "slide-video";

FramerLuhsnYHvd.defaultProps = {height: 807, width: 1400};

addPropertyControls(FramerLuhsnYHvd, {variant: {options: ["Rgf0c6E73", "LqrusrREa", "sorjaZuFM"], optionTitles: ["desktop", "tablet", "mobile"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerLuhsnYHvd, [{explicitInter: true, fonts: []}, ...VideoFonts], {supportsExplicitInterCodegen: true})